import Rat, { BigRational } from "big-rational";

/**
 * Represents a dimensional value. For example, 1 kilogram, or 7 degrees per
 * Kilobyte. Only dimensions that intersect at zero can be represented (5
 * degrees Kelvin is acceptable, 5 degrees Celsius is not).
 */
export class Dim {
    readonly magnitude: BigRational;
    readonly units: Map<symbol, number>;

    constructor(
        magnitude: number | string | BigRational,
        units?: Map<symbol, number>
    ) {
        this.magnitude = Rat(magnitude);
        this.units = units ?? new Map();
    }

    /**
     * Returns if another Dimensional Value has identical dimensions.
     * @param other The other value to compare units of.
     */
    eqUnits(other: Dim): boolean {
        for (const [unit, value] of this.units) {
            if (value !== (other.units.get(unit) ?? 0)) {
                return false;
            }
        }

        for (const [unit, value] of other.units) {
            if (value !== (this.units.get(unit) ?? 0)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Returns a new dimensional value by adding the given value's magnitude.
     * @param other The other value to add.
     * @throws If the two values have differing dimensions.
     */
    add(other: Dim): Dim {
        if (!this.eqUnits(other)) {
            throw new Error("Cannot add two values of different dimensions.");
        }

        return new Dim(this.magnitude.add(other.magnitude), this.units);
    }

    /**
     * Returns a new dimensional value by subtracting the given value's magnitude.
     * @param other The other value to subtract.
     * @throws If the two values have differing dimensions.
     */
    sub(other: Dim): Dim {
        if (!this.eqUnits(other)) {
            throw new Error(
                "Cannot subtract two values of different dimensions."
            );
        }

        return new Dim(this.magnitude.subtract(other.magnitude), this.units);
    }

    /**
     * Returns a new dimensional value by multiplying the given value's
     * magnitude and adding dimensions.
     * @param other The other value to multiply.
     * @example new Dim(2, [Length]).mul(new Dim(2, [Length]))
     * // == 4 Length^2
     */
    mul(other: Dim): Dim {
        const newMap = new Map<symbol, number>(this.units);
        for (const [unit, value] of other.units) {
            newMap.set(unit, (newMap.get(unit) ?? 0) + value);
        }
        return new Dim(this.magnitude.multiply(other.magnitude), newMap);
    }

    /**
     * Returns a new dimensional value by dividing the given value's magnitude
     * and subtracting dimensions.
     * @param other The other value to divide.
     * @example new Dim(2, [Length]).div(new Dim(2, [Time]))
     * // == 1 Length/Time
     */
    div(other: Dim): Dim {
        const newMap = new Map<symbol, number>(this.units);
        for (const [unit, value] of other.units) {
            newMap.set(unit, (newMap.get(unit) ?? 0) - value);
        }
        return new Dim(this.magnitude.divide(other.magnitude), newMap);
    }

    /**
     * Returns a new dimensional value by negating the given value's magnitude.
     */
    negate(): Dim {
        return new Dim(this.magnitude.negate(), this.units);
    }
}
