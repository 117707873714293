import { Dim } from "./Dim";
import { A, B, I, J, L, M, N, T, Th } from "./SIDimensions";

function u(
    factor: string | number,
    positiveUnits: symbol[],
    negativeUnits?: symbol[]
): Dim {
    const map = new Map<symbol, number>();
    for (const unit of positiveUnits) {
        map.set(unit, (map.get(unit) ?? 0) + 1);
    }
    if (negativeUnits) {
        for (const unit of negativeUnits) {
            map.set(unit, (map.get(unit) ?? 0) - 1);
        }
    }
    return new Dim(factor, map);
}
export const units: { [suffix: string]: [string, Dim] } = {
    // SI Base Units
    s: ["second", u(1, [T])],
    m: ["metre", u(1, [L])],
    g: ["gram", u("0.001", [M])],
    A: ["ampere", u(1, [I])],
    K: ["kelvin", u(1, [Th])],
    mol: ["mole", u(1, [N])],
    cd: ["candela", u(1, [J])],
    bit: ["bit", u(1, [B])],
    rad: ["radian", u(1, [A])],

    // SI Units
    sr: ["steradian", u(1, [A, A])],
    Hz: ["hertz", u(1, [], [T])],
    N: ["newton", u(1, [M, L], [T, T])],
    Pa: ["pascal", u(1, [M], [L, T, T])],
    J: ["joule", u(1, [M, L, L], [T, T])],
    W: ["watt", u(1, [M, L, L], [T, T, T])],
    C: ["coulomb", u(1, [T, I])],
    V: ["volt", u(1, [M, L, L], [T, T, T, I])],
    F: ["farad", u(1, [T, T, T, T, I, I], [M, L, L])],
    Ω: ["ohm", u(1, [M, L, L], [T, T, T, I, I])],
    S: ["siemens", u(1, [T, T, T, I, I], [M, L, L])],
    Wb: ["weber", u(1, [M, L, L], [T, T, I])],
    T: ["tesla", u(1, [M], [T, T, I])],
    H: ["henry", u(1, [M, L, L], [T, T, I, I])],
    lm: ["lumen", u(1, [J, A, A])],
    lx: ["lux", u(1, [J, A, A], [L, L])],
    Bq: ["becquerel", u(1, [], [T])],
    Gy: ["gray", u(1, [L, L], [T, T])],
    Sv: ["sievert", u(1, [L, L], [T, T])],
    kat: ["katal", u(1, [N], [T])],

    // Accepted Non-SI Units
    min: ["minute", u(60, [T])],
    h: ["hour", u(3600, [T])],
    d: ["day", u(86400, [T])],
    au: ["astronomical unit", u(149597870700, [L])],
    deg: ["degree", u(Math.PI / 180, [A])],
    ha: ["hectare", u(10000, [L, L])],
    L: ["litre", u(0.001, [L, L, L])],
    l: ["litre", u(0.001, [L, L, L])],
    t: ["tonne", u(1000, [M])],
    Da: ["dalton", u("0.00000000000000000000000000166053904020", [M])],
    eV: ["electronvolt", u("0.00000000000000000016021766", [M, L, L], [T, T])],

    // https://en.wikipedia.org/wiki/Conversion_of_units#Length
    // Non-SI Units
    Å: ["angstrom", u("0.0000000001", [L])],
    ch: ["chain", u("20.1168", [L])],
    ftm: ["fathom", u("1.8288", [L])],
    ft: ["foot (international)", u("0.3048", [L])],
    fur: ["furlong", u("201.168", [L])],
    in: ["inch (international)", u("0.0254", [L])],
    ly: ["light-year", u("9460730472580800", [L])],
    ln: ["line", u("0.002116666666", [L])],
    lnk: ["link", u("0.201168", [L])],
    mi: ["mile (international)", u("1609.344", [L])],
    nl: ["nautical league", u(5556, [L])],
    nmi: ["nautical mile (international)", u(1852, [L])],
    pc: ["parsec", u("30856775814913700", [L])],
    rd: ["rod", u("5.0292", [L])],
    rope: ["rope", u("6.096", [L])],
    yd: ["yard (international)", u("0.9144", [L])],

    ac: ["acre (international)", u("4046.8564224", [L, L])],
    bd: ["board", u("0.00774192", [L, L])],
    ro: ["rood", u("1011.7141056", [L, L])],
    sqch: ["square chain", u("404.68564224", [L, L])],
    sqft: ["square foot", u("0.09290306", [L, L])],
    sqin: ["square inch", u("0.00064516", [L, L])],
    sqmi: ["square mile", u("2589988.110336", [L, L])],
    sqyd: ["square yard", u("0.83612736", [L, L])]
};
