import Rat, { BigRational } from "big-rational";

export const prefixes: { [prefix: string]: [string, BigRational] } = {
    Z: ["zetta", Rat("1000000000000000000000")],
    E: ["exa", Rat("1000000000000000000")],
    P: ["peta", Rat("1000000000000000")],
    T: ["tera", Rat("1000000000000")],
    G: ["giga", Rat("1000000000")],
    M: ["mega", Rat("1000000")],
    k: ["kilo", Rat("1000")],
    h: ["hecto", Rat("100")],
    da: ["deca", Rat("10")],
    d: ["deci", Rat("0.1")],
    c: ["centi", Rat("0.01")],
    m: ["milli", Rat("0.001")],
    μ: ["micro", Rat("0.000001")],
    n: ["nano", Rat("0.000000001")],
    p: ["pico", Rat("0.000000000001")],
    f: ["femto", Rat("0.000000000000001")],
    a: ["atto", Rat("0.000000000000000001")],
    z: ["zepto", Rat("0.000000000000000000001")],
    y: ["yocto", Rat("0.000000000000000000000001")]
};

export function getPrefix(
    unit: string
): { name: string; factor: BigRational; modifiedInput: string } | undefined {
    // Special case "da" since "d" is also a prefix of it
    if (unit.startsWith("da")) {
        const data = prefixes["da"];
        return {
            name: data[0],
            factor: data[1],
            modifiedInput: unit.substring(2)
        };
    }

    for (const prefix of Object.keys(prefixes)) {
        if (!unit.startsWith(prefix)) {
            continue;
        }

        const data = prefixes[prefix];
        return {
            name: data[0],
            factor: data[1],
            modifiedInput: unit.substring(prefix.length)
        };
    }
}
