import { Dim } from "./Dim";
import { prefixes } from "./SIPrefixes";
import { units } from "./SIUnits";

export function create(magnitude: string, unitSuffix: string): Dim {
    const magDim = new Dim(magnitude);

    // Dimensionless value
    if (unitSuffix === "") {
        return magDim;
    }

    let refInfo = units[unitSuffix];
    if (refInfo) {
        return magDim.mul(refInfo[1]);
    }

    for (const prefix of Object.keys(prefixes)) {
        if (!unitSuffix.startsWith(prefix)) {
            continue;
        }

        refInfo = units[unitSuffix.substring(prefix.length)];
        if (refInfo) {
            return magDim.mul(refInfo[1]).mul(new Dim(prefixes[prefix][1]));
        }
    }

    throw new Error(`Unknown unit ${unitSuffix}`);
}
