import { Dim } from "./Dim";

/** T, Time Dimension, 1 second */
export const T = Symbol("T, Time Dimension, 1 second");
/** L, Length Dimension, 1 metre */
export const L = Symbol("L, Length Dimension, 1 metre");
/** M, Mass Dimension, 1 kilogram */
export const M = Symbol("M, Mass Dimension, 1 kilogram");
/** I, Electric Current Dimension, 1 ampere */
export const I = Symbol("I, Electric Current Dimension, 1 ampere");
/** Θ, Thermodynamic Temperature Dimension, 1 kelvin */
export const Th = Symbol("Θ, Thermodynamic Temperature Dimension, 1 kelvin");
/** N, Amount of Substance Dimension, 1 mole */
export const N = Symbol("N, Amount of Substance Dimension, 1 mole");
/** J, Luminous Intensity Dimension, 1 candela */
export const J = Symbol("J, Luminous Intensity Dimension, 1 candela");
/** B, Information Dimension, 1 bit */
export const B = Symbol("B, Information Dimension, 1 bit");
/** A, Angular Offset, 1 radian*/
export const A = Symbol("A, Angular Offset, 1 radian");

/** A mapping of dimensional symbol shorthands to their symbols. */
export const dimensionSymbolMap = {
    T,
    L,
    M,
    I,
    Th,
    N,
    J,
    B,
    A
};

const superscriptNumbers: { [char: string]: string } = {
    "0": "⁰",
    "1": "¹",
    "2": "²",
    "3": "³",
    "4": "⁴",
    "5": "⁵",
    "6": "⁶",
    "7": "⁷",
    "8": "⁸",
    "9": "⁹",
    "-": "⁻"
};
function superscript(value: number): string {
    const str = value.toString();
    const output: string[] = [];
    for (const char of str) {
        output.push(superscriptNumbers[char]);
    }
    return output.join("");
}

const dimensionSuffixes = {
    [T]: "s",
    [L]: "m",
    [M]: "kg",
    [I]: "A",
    [Th]: "K",
    [N]: "mol",
    [J]: "cd",
    [B]: "bit",
    [A]: "rad"
};
/**
 * Returns a canonical representation for an SI Dimensional value.
 * @param value The dimensional value to return the string representation for.
 * @throws Throws an `Exception` if the value contains a dimension not in
 * `dimensionSuffixes`.
 */
export function toStringDimensions(value: Dim): string {
    const output: string[] = [];
    for (const [unit, power] of value.units) {
        if (power === 0) {
            continue;
        }
        output.push(dimensionSuffixes[unit as keyof typeof dimensionSuffixes]);
        if (power !== 1) {
            output.push(superscript(power));
        }
    }
    return output.join("");
}
