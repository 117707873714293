
import { computed, defineComponent, ref } from "vue";
import { toStringDimensions } from "./lib/SIDimensions";
import { prefixes } from "./lib/SIPrefixes";
import { units } from "./lib/SIUnits";
import { parser } from "./parser/parser.jison.yml";

const inprogressUnit = /[a-zA-Z]+$/;

export default defineComponent({
    setup() {
        const input = ref("");

        const possibleUnits = computed(() => {
            const match = input.value.match(inprogressUnit);
            if (!match) {
                return [];
            }

            const searchStrings = [match[0]];
            for (const prefix of Object.keys(prefixes)) {
                if (
                    match[0].startsWith(prefix) &&
                    match[0].length > prefix.length
                ) {
                    searchStrings.push(match[0].substring(prefix.length));
                }
            }

            const output: string[] = [];
            for (const suffix of Object.keys(units)) {
                if (searchStrings.some(s => suffix.startsWith(s))) {
                    output.push(suffix + ": " + units[suffix][0]);
                }
            }

            return output;
        });

        const output = computed(() => {
            try {
                const result = parser.parse(input.value);
                switch (result.type) {
                    case "RESULT":
                        return (
                            result.value.magnitude.toDecimal() +
                            toStringDimensions(result.value)
                        );
                    case "CONVERT": {
                        if (!result.value.eqUnits(result.target)) {
                            throw new Error(
                                `Cannot convert to incompatible unit. From: ${toStringDimensions(
                                    result.value
                                )}, to: ${toStringDimensions(result.target)}`
                            );
                        }
                        const targetString = input.value.split(/\bto\b/);
                        return (
                            result.value
                                .div(result.target)
                                .magnitude.toDecimal() +
                            targetString[targetString.length - 1].trim()
                        );
                    }
                }
            } catch (ex) {
                return "" + ex;
            }
        });

        return { input, output, possibleUnits };
    }
});
